ul.tabs {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 100%;
  display: flex;
  border-bottom:1px solid #000
}

ul.tabs li {
  margin: 0;
  cursor: pointer;
  padding: 0 0 10px 0;
  margin-right: 30px;
  font-size: 22px;
 
  position: relative;
  color: rgba(0,0,0,0.5);
  transition:color 0.4s;
}

ul.tabs li:after {
  position: absolute;
  content: '';
  display: block;
  bottom: -2px;
  left: 0;
  right: 0;
  width: 0; 
  height: 3px;
  background-color: $color-red;
  transition:width 0.4s;
}


ul.tabs li.active:after {
  width: 100%;
}

ul.tabs li:hover {
  color: #000
}

ul.tabs li.active {
  display: block;
  color:#000
}

.tab_container {
  width: 100%;
}

.tab_content {
  padding: 0;
  display: none;
}

.tab_drawer_heading { display: none; }

@media screen and (max-width: 540px) {
  ul.tabs {
    display: none
  }
  .tab_drawer_heading {
    padding: 30px 0 20px 0;
    border-bottom: 1px solid #707070;
    cursor: pointer;
    position: relative;
    font-size: 18px;
    margin: 0;
    color: #24298F;
    font-weight: 400;
    display: block;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .tab_drawer_heading:before {
    height: 12px;  width: 12px;  position: absolute;  right: 0;  content: " ";  top: 38px;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.904' height='8.952' viewBox='0 0 15.904 8.952'%3E %3Cpath id='Path_208' data-name='Path 208' d='M0%2C0%2C6.538%2C6.538%2C0%2C13.076' transform='translate(14.49 1.414) rotate(90)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E %3C/svg%3E "); background-size: 12px 12px;  background-position:center;  transition: transform 0.2s ;  cursor: pointer;
  }
  .d_active:before { transform: rotate(180deg) }

  .d_active {
    color: #24298F;
    
  }
}


.list-awards {list-style: none;padding: 0;margin: 0;}
.list-awards li{padding: 22px 0 22px 35px; border-bottom: 1px solid #707070; position: relative; line-height: 1.5}
.list-awards li:last-child{border-bottom: none}
.list-awards li:before{ content: ''; position: absolute; left: 0;top: 29px; width: 12px;height: 12px;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.619' height='12.001' viewBox='0 0 12.619 12.001'%3E %3Cpath id='Path_379' data-name='Path 379' d='M-7706.316-1044.751l1.949%2C3.95%2C4.36.634-3.155%2C3.075.745%2C4.342-3.9-2.05-3.9%2C2.05.745-4.342-3.155-3.075%2C4.36-.634Z' transform='translate(7712.626 1044.751)' fill='%23f23d41'/%3E %3C/svg%3E ");background-repeat: no-repeat;background-position: center;}
.accordion-container { padding: 0 0 30px;}

.accordion { margin: 0 auto; padding: 0; list-style: none; }
.accordion.accordion-faq.accordion { border-top: none; }
.accordion > li { border-bottom: 1px solid #707070; counter-increment: item; position: relative;margin: 0!important;padding-left: 0!important; }
.accordion > li:before {display: none!important;}

.accordion.accordion-faq > li { display: block }
.accordion h4, .accordion .h4 { margin: 0!important; transition: all 0.4s; font-weight: 400; font-size: 25px; padding: 34px 40px 34px 60px; line-height: 1.3; color: $color-blue }
.accordion li.active h4, 
.accordion li:hover h4,
.accordion li.active .h4, 
.accordion li:hover .h4 { color: $color-blue }
.accordion li > a:hover h4, .accordion li > a:hover .h4 {color:$body-color}

.accordion li > a { display: block; width: 100%; cursor: pointer; }
.accordion-item { display: none; max-width: 900px; padding: 0; padding: 30px 0 50px 0; }
.accordion.accordion-faq .accordion-item { width: 100%; padding: 0; }
.accordion li > a:after {  height: 12px;  width: 12px;  position: absolute;  right: 0;  content: " ";  top: 44px;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.904' height='8.952' viewBox='0 0 15.904 8.952'%3E %3Cpath id='Path_208' data-name='Path 208' d='M0%2C0%2C6.538%2C6.538%2C0%2C13.076' transform='translate(14.49 1.414) rotate(90)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E %3C/svg%3E "); background-size: 12px 12px;  background-position:center;  transition: transform 0.2s ;  cursor: pointer;}
.accordion li > a h4:before, .accordion li > a .h4:before {content: counter(item, decimal-leading-zero)"."; padding-right: 30px;color: $color-red; position: absolute;left: 0}



.accordion > li.active a:after { transform: rotate(180deg) }
.accordion > li .acc-container { padding: 0; margin: 60px 0; list-style: none; }
.accordion > li ul { padding: 0; margin: 0 0 20px 0; list-style: none; }
.accordion > li ul li { display: block; position: relative; padding-left: 22px; padding-bottom: 10px; line-height: 1.5; }
.accordion ul li:after { content: ''; font-weight: bold; display: inline-block; width: 4px; height: 4px; background-color: #121212; position: absolute; left: 0; top: 9px; }
@include media-breakpoint-down(xl) { 
	.accordion h4, .accordion .h4 { font-size: 22px; }
}
@include media-breakpoint-down(md) { 
	.accordion h4, .accordion .h4 { font-size: 19px; padding: 30px 40px 30px 50px }
  .accordion li > a:after { top: 38px; }
}



@include media-breakpoint-down(lg) {
  .accordion-mobile { }
  .accordion-mobile-title {padding:30px 0 20px 0; border-bottom: 1px solid #707070; cursor: pointer; position: relative; font-size: 18px}
  .accordion-mobile-content {}
  .accordion-mobile-content { display: none; max-width: 900px; padding: 0; padding: 30px 0 50px 0; }
  .accordion-mobile-title:before {height: 12px;  width: 12px;  position: absolute;  right: 0;  content: " ";  top: 38px;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.904' height='8.952' viewBox='0 0 15.904 8.952'%3E %3Cpath id='Path_208' data-name='Path 208' d='M0%2C0%2C6.538%2C6.538%2C0%2C13.076' transform='translate(14.49 1.414) rotate(90)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E %3C/svg%3E "); background-size: 12px 12px;  background-position:center;  transition: transform 0.2s ;  cursor: pointer;}
  .accordion-mobile-title.active:before { transform: rotate(180deg) }
  .accordion-mobile  .list-1 {margin-top: 20px;}
}
@include media-breakpoint-up(lg) {
.accordion-mobile-content {display: block!important;height: auto!important;}
  }
body {
	-webkit-font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0,0,0,0);
}


/* ::-moz-selection { background: $color-pink; }
::selection { background: $color-pink; } */

#preloader { position: fixed; top: 0; left: 0; right: 0; bottom: 0;background:#fff; z-index: 99999; }

.barba-container {margin: 0 auto;background:#fff;}

@media (min-width: 2000px) {
	.barba-container {overflow: hidden;max-width: 2000px;} 
}



a {transition: color 0.4s}

.link-inherit {color: inherit; text-decoration: none;}
.link-inherit:hover {color: inherit; text-decoration: none;}

.opacity-white {background: rgba(255,255,255,0.8);}
.opacity-black {background: rgba(0,0,0,0.8);}

.cursor-pointer {cursor: pointer;}

.text-vertical {writing-mode: tb-rl;transform: rotate(-180deg);display: inline-block;line-height: 1;}
.text-vertical.position-absolute-md {left: 0;top: 0;}
.text-vertical.position-absolute-sm {left: 0;top: 0;}

.text-vertical a {color: $color-red; text-decoration: none;}
.text-vertical a:hover {color: #000}

.position-absolute-full {position: absolute;top: 0;bottom: 0;left: 0;right: 0;}
.cursor-pointer {cursor: pointer;}

.text-left {text-align: left;}

.z-1 {z-index: 1}
.z-2 {z-index: 2}
.z-3 {z-index: 3}
.z-4 {z-index: 4}
.z-5 {z-index: 5}
.z-6 {z-index: 6}
.z-7 {z-index: 7}
.z-8 {z-index: 8}
.z-9 {z-index: 9}

.object-position-right, .object-position-right img {object-position:right!important}



.overlay {position: absolute;top: 0;left: 0;right: 0;bottom: 0;background-color: rgba(0,0,0,0.5)}

.z-9999 {z-index: 9999}
.t0 {top: 0;}
.t-big {top: 120px;}
.t-big {top: 124px;}
.t-min-fix {top: -7px; position: relative;}

.lh-1-2 {line-height: 1.2}
.lh-1-5 {line-height: 1.5}
.mw-300 {max-width: 300px}
.mw-450 {max-width: 450px}
.mw-500 {max-width: 550px}
.mw-660 {max-width: 660px}
.mw-700 {max-width: 700px}
.mw-900 {max-width: 900px}
.mw-1000 {max-width: 1000px}
.h60p {height: 60px;}
.h100p {height: 100px;}
.flex-grow-1-5 {flex:1.5}
.flex-grow-2 {flex:2}
.flex-120 {    flex: 0 0 120px;}
.flex-200 {    flex: 0 0 200px;}

.flex-fixed-1 {flex:0 0 343px}

.h-0 {height: 0}

.flex-grow-85 {flex: 0 0 85px;}
.flex-grow-330 {flex: 0 0 250px;}


.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

@media (min-width: 1600px) {
	.d-xxxl-none {display: none}
}


.image-174 {padding-bottom: 174%;}
.image-60 {padding-bottom: 60%;}
.image-100 {padding-bottom: 100%;}
.image-104 {padding-bottom: 104%;}
.image-104 {padding-bottom: 104%;}
.image-127 {padding-bottom: 127%;}


.g-6, .gx-6 {--bs-gutter-x: 8rem;}
.mb-6 {margin-bottom: 5rem !important;}


@include media-breakpoint-up(xl) { 
    .mw-xl-image {max-width: 300px;}
    .mw-xl-small {max-width: 340px}
}
@include media-breakpoint-up(lg) { 
    .pe-lg-6 {padding-right: 80px;}
	.border-lg-end {border-right: 1px solid $body-color}

	.h-lg-0 {height: 0;}
}
@include media-breakpoint-up(sm) { 
	.position-absolute-sm {position: absolute;}
}

@include media-breakpoint-up(md) { 
	.position-absolute-md {position: absolute;}
}


@include media-breakpoint-down(xxl) { 
	.g-6, .gx-6 {--bs-gutter-x: 5rem;}
}

@include media-breakpoint-down(xl) { 
	
    .g-0-xl, .g-0-xl {--bs-gutter-x: 0rem!important;}
}

@include media-breakpoint-down(lg) { 
	.br-lg-none br{ display: none;}
}
@include media-breakpoint-down(md) { 
	.image-md-68 {padding-bottom: 68%!important;}
	.outdent-md {@include margin(0 -8rem);}
	.outdent-left-md {@include margin(0 0 0 -8rem);}
	.outdent-right-md {@include margin(0-8rem 0 0);}

	.border-top-md-down {border-top: 1px solid  #DCDDE2}
    .pb-0-md-d {padding-bottom: 0!important;}
    .py-0-md-d {padding-bottom: 0!important;padding-top: 0!important;}

}
@include media-breakpoint-down(sm) { 
	.outdent-md {@include margin(0 -4rem);}
	.outdent-left-md {@include margin(0 0 0 -4rem);}
	.outdent-right-md {@include margin(0-4rem 0 0);}

    .image-sm-68 {padding-bottom: 68%!important;}
    .pb-0-sm-d {padding-bottom: 0!important;}
    .py-0-sm-d {padding-bottom: 0!important;padding-top: 0!important;}

    .flex-grow-sm-50 {flex: 0 0 50px;}

    .g-6, .gx-6 {--bs-gutter-x: calc(2.58rem + 4.2vw);}
}



.link-unstyled, .link-unstyled:hover {color: inherit; text-decoration: inherit;}
.link-underline,.link-underline:hover {color: inherit; text-decoration: underline;}
.link-underline:hover {text-decoration: none;}


/* HOME */
.header-home {display: flex;flex-direction: column;justify-content: flex-end; min-height: auto; width: 100%;  height: auto; position: relative;}
.header-home video {width: 100%; min-height: 280px; object-fit: cover; position: absolute; z-index: 1; /* filter:grayscale(100%); */opacity: 1;height: 100%;}


//.video-bg { position: absolute; bottom: 0; left: 0; height: 100%; width: 100%; z-index: 2; webkit-backdrop-filter: saturate(95%) blur(18px);   backdrop-filter: saturate(95%) blur(18px);}
.video-bg { position: absolute; bottom: 0; left: 0; height: 100%; width: 100%; z-index: 2; /* webkit-backdrop-filter: saturate(0%) blur(20px) contrast(2);   backdrop-filter: saturate(0%) blur(15px) contrast(2); */}

//.radial-gradient{position:absolute;top:0;left:0;bottom:0;right:0;opacity:1;z-index:3;mix-blend-mode:multiply;transition:background 0.6s;}
.radial-gradient{position:absolute;top:0;left:0;bottom:0;right:0;/* opacity:0.8; */z-index:3;transition:background 0.6s;}


.header-home-content {position: relative; z-index: 6;height:70vh; max-height: 1080px; min-height: 600px;}
.header-home-content .inner {height:70vh; max-height: 1080px; min-height: 600px}

@include media-breakpoint-up(lg) {
	.header-home-content {height:100vh; }
	.header-home-content .inner {height:100vh;}

}


.slider-main-pagination {text-align: center;margin-top: 30px;margin-bottom: 13px;}
.slider-main-pagination span {color: #fff;padding:3px; cursor: pointer;}
.slider-main-pagination span svg { transition:opacity 0.4s;opacity: 0.3}
.slider-main-pagination span:hover svg  {opacity:0.6}
.slider-main-pagination span.is-selected svg  {opacity:1}


.elem {position: absolute; z-index: 7; pointer-events:none;}
.elem span {width: 20vw;max-width: 320px; min-width: 200px; height: 5px;background: $color-red;display: inline-block;}

.elem-1 {left: -4px;top: 10%;}
.elem-1.nav-spacer-fix {left: -4px;top: calc(120px + 10%);}
.elem-1 span {transform: rotate(69deg);transform-origin:left}

.elem-2 {right: 4%;bottom: 0;}
.elem-2 span {transform: rotate(-69deg) translateX(-33.3333%);  transform-origin:bottom left}

.elem-3 {left: -4px;top: 100px;}
.elem-3 span {transform: rotate(69deg);transform-origin:left}

.elem-4 {right: 0;top: 0;transform: translateX(50%);}
.elem-4 span {transform: rotate(-69deg) translateX(-33.3333%);}


.elem-5 {
    right: 0;
    bottom: -10vw;
    transform: rotate(21deg) translateY(-15%);
    transform-origin: right center;
    width: 5px;
    height: 20vw;
    background-color: #EB3F41;
    max-width: 250px;
    min-width: auto;
}
.elem-6 {
    right: 50%;
    bottom: -15vw;
    transform: rotate(21deg) translateY(-15%);
    transform-origin: right center;
    width: 5px;
    height: 30vw;
    background-color: #EB3F41;
    max-width: 250px;
    min-width: auto;
}


@include media-breakpoint-down(xxl) {
	.elem span {width: 17vw;max-width: 250px; min-width: auto;}
}
@include media-breakpoint-down(xl) {
	.hide-xl {display: none}
}
@include media-breakpoint-down(lg) {
	.elem span {width: 16vw;}
	.hide-lg {display: none}
}

@include media-breakpoint-down(md) {
	.hide-md {display: none}
}
@include media-breakpoint-down(sm) {
	.header-home {overflow: hidden;}
	.hide-sm {display: none}
	.elem-2 {bottom: 65%;width: 0;right: -4px}
	.elem-2 span {transform: rotate(-69deg) translateX(-94%);width: 200px;}

	.slider-main-pagination {position: absolute;bottom: 60px;right: calc(1.425rem + 2.1vw);}
	.slider-main h1 {max-width: 90%!important}
}



/* Logos */
.row-logos img {max-width: 100%;}
@include media-breakpoint-up(sm) {
	.row-logos > div:nth-child(3n) { text-align: right;}
	.row-logos > div:nth-child(3n+2) { text-align: center;}
}

@include media-breakpoint-down(md) {
	.row-logos img {transform:scale(0.75)}
}

@include media-breakpoint-down(sm) {
	.row-logos > div:nth-last-child(1):nth-child(odd) {width: 100%;text-align: center;}
}

video:focus {outline: none;}


/* SERVICES */

/* Hero */
.header-services {padding-top: 85px;}
.image-angle-hero { position: relative; overflow: hidden; padding-bottom: 100%; width: 100%; }
.image-angle-hero img { position: absolute; height: 100%; width: 100%; object-fit: cover; object-position: top center;}
.image-angle-hero:after { content: ''; display: block; position: absolute; bottom: 0; top: 0; width: 100%; top: -50%; left: -100%; transform: rotate(21deg) translateY(10px); background-color: #fff;transform-origin: right bottom;}
.image-angle-hero.pink:after {background-color: $color-pink}

.image-angle-hero.less {padding-bottom: 90%;}

@include media-breakpoint-down(xl) {
	.image-angle-hero {padding-bottom: 120%;overflow: visible;}
	.image-angle-hero img {width: calc(3.05rem + 6.6vw + 100%);}
}

@include media-breakpoint-down(lg) {
	.header-services {padding-top: 0;}
	.image-angle-hero {padding-bottom: 60%;}
	.image-angle-hero img {width: calc(2.95rem + 5.4vw + 100%);}
}

@include media-breakpoint-down(md) {
	.image-angle-hero {padding-bottom: 75%;}
	.image-angle-hero img {width: calc(2.95rem + 5.4vw + 100%);}

	.image-angle-hero.angle-md-none:after {display: none;}
	.angle-full-md img {margin-left: calc(-2.95rem - 5.4vw); width: calc(5.9rem + 10.8vw + 100%);}
}

@include media-breakpoint-down(sm) {
	.image-angle-hero img {width: calc(1.425rem + 2.1vw + 100%);}

	.image-angle-hero.angle-sm-none:after {display: none;}
	.angle-full-md img {margin-left: calc(-1.425rem - 2.1vw); width: calc(2.85rem + 4.2vw + 100%);}
}


/* Image angle left */
.image-angle-left { position: relative; overflow: hidden; padding-bottom: 152%; width: calc(13rem + 100%); min-height: 100%;height: 640px;}
.image-angle-left img { position: absolute; height: 100%; width: 100%; object-fit: cover; object-position: right; right: 0;}
.image-angle-left:after { content: ''; display: block; position: absolute; bottom: 0; top: 0; width: 100%; top: -50%; left: -100%; transform: rotate(21deg) translateY(10px); background-color: #fff;transform-origin: right bottom;}


@include media-breakpoint-down(xxl) {
	.image-angle-left {width: calc(9rem + 100%); }
}

@include media-breakpoint-down(xl) {
	.image-angle-left {padding-bottom: 120%;width: calc(3.05rem + 6.6vw + 100%);}
	.image-angle-left img {}
}



/* Split col*/
.image-angle-right.blue:after { background-color: $color-blue; }
.image-angle-right.green:after { background-color: $color-green; }
.image-angle-right.purple:after { background-color: $color-purple; }

@include media-breakpoint-up(lg) {
	.image-angle-right {position: relative; overflow: hidden;padding-bottom: 100%;width: 100%; min-height: 100%;height: 700px;width: calc(13rem + 100%);margin-left: -13rem;}
	.image-angle-right img { position: absolute; height: 100%; width: 100%; object-fit: cover; object-position: center; }
	.image-angle-right:after { content: ''; display: block; position: absolute; bottom: 0; top: 0; width: 100%; bottom: -50%; right: -100%; transform: rotate(21deg) translateY(-10px); transform-origin: left top;}
}

@include media-breakpoint-down(xxl) {
	.image-angle-right {width: calc(9rem + 100%);margin-left: -9rem; }
}

@include media-breakpoint-down(xl) {
	.image-angle-right {width: calc(3.05rem + 6.6vw + 100%);margin-left: calc(-3.05rem - 6.6vw);}

	.py-xl-none-down {padding-top: 0!important;padding-bottom: 0!important;}
	.px-xl-none-down {padding-left: 0!important;padding-right: 0!important;}
}



@include media-breakpoint-down(lg) {

	.image-angle-right {position: relative; overflow: hidden;padding-bottom: 175%;width: 100%;margin-left: 0;}
	.image-angle-right img { position: absolute; height: 100%; width: 100%; object-fit: cover; object-position: center; }
	.ps-lg-none-down {padding-left: 0!important;}
	.px-lg-none-down {padding-left: 0!important;padding-right: 0!important;}
	.py-lg-none-down {padding-top: 0!important;padding-bottom: 0!important;}

	.s-sm-left {width: 32%;}
	.s-sm-right { width: 68%;padding-left: 50px;padding-right: calc(1.425rem + 2.1vw);}

	.section-service {transition:background-color 0.4s}
	.section-service.active { background-color: rgba(46,48,129,0.05)}

	.content-opener {overflow: hidden;}

	//.content-opener 		.content-opener-inner {height: 0;transition: opacity 0.4s ease 0.2s, max-height 0.4s 0.2s; }
    //.section-service.active .content-opener-inner{height: auto}

	.content-opener 		.content-opener-inner {display: none; }
    .section-service.active .content-opener-inner{}

    .content-toggle {cursor: pointer;}
    .content-toggle .link-icon div {}
    .content-toggle svg {transition:transform 0.4s 0.2s; transform-origin:center center; display: inline-block;padding-right: 0;}
    .content-toggle.open svg{transform:rotate(180deg);}


}


/* Case studies */

.cta-case-study { color: inherit;text-decoration: none;}
.cta-case-study:hover { color: inherit;text-decoration: none;}



@include media-breakpoint-up(md) {
	.sections-case-study {border-bottom:1px solid  #DCDDE2}
	.bg-hover-grey {transition:background-color 0.4s;}
	.bg-hover-grey:hover { background-color: #F3F3F8}
	.bg-hover-grey .btn:hover em { background-color: #fff}

	
}

@include media-breakpoint-up(xl) {
	.load-more-container .sections-case-study {border-bottom:none}
	.load-more-container .sections-case-study:hover { background-color: #fff}
	.load-more-container .sections-case-study .btn:hover em {background-color: #F3F3F8;}
}


/* Insights */


.counter .load-more-block{counter-increment:item -1;}
.counter .load-more-block .number:before {text-align: right;display: block; content: "#"counter(item, decimal-leading-zero);}


.row-insights {}
.row-insights .item-insight {color: inherit;text-decoration: none; padding-top: 4rem;}
.row-insights .item-insight:first-child { padding-top: 0;}

.row-insights .item-insight .border-bottom {border-bottom:1px solid #707070!important}
.row-insights .item-insight .small {font-size: 16px; font-family: $font-secondary}
@include media-breakpoint-up(md) {
	.row-insights .item-insight:nth-child(-n+2) {padding-top: 0;}
}

@include media-breakpoint-up(lg) {
	.row-insights .item-insight { padding-top: 5rem;}
}

@include media-breakpoint-up(xl) {
	.row-insights .item-insight {border-right:1px solid #000000!important;}
	.row-insights .item-insight:nth-child(-n+3) {padding-top: 0;}
	.row-insights .item-insight:nth-child(3n) {border-right:none!important}
}
@include media-breakpoint-between(md, xl) {
	.row-insights .item-insight {border-right:1px solid #000000!important;}
	.row-insights .item-insight:nth-child(-n+2) {padding-top: 0;}
	.row-insights .item-insight:nth-child(2n) {border-right:none!important}

	.insights-cta .col-md-6:last-child{display: none!important;}
}

@include media-breakpoint-down(xl) {
	.row-insights .item-insight {color: inherit;text-decoration: none;}

}

@include media-breakpoint-down(md) {
	.row-insights .item-insight .lead-2{ font-size: 22px}
	.row-insights .item-insight .mb-6{ margin-bottom: 4rem!important;}
}

@include media-breakpoint-only(sm) {
	.ml-sm-min-30 {margin-left: -30px;}
}

@include media-breakpoint-down(md) {
	.ml-md-min-30 {margin-left: -8vw;}
}



.btn-insight {font-size: 16px; color: $color-red; text-decoration: none;display: block; white-space: nowrap; line-height: 1; padding-bottom: 30px; margin-top: 50px;}
.btn-insight:hover {color: $color-red;}
.btn-insight div {display: flex;}
.btn-insight span, .btn em {display: flex; align-items:center;line-height: 0}
.btn-insight strong {font-weight: 400}
.btn-insight span{padding: 0 0 0 20px; transform: translateX(0);transition:opacity 0.4s, transform 0.4s, color 0.4s  }
.btn-insight:hover span{ transform: translateX(3px);color:$body-color }
@include media-breakpoint-up(xl) {
	.no-touch .row-insights .item-insight .btn-insight span{ opacity: 0; }
	.no-touch .row-insights .item-insight:hover .btn-insight span{ opacity: 1;  }
}
@include media-breakpoint-down(md) {
.btn-insight {padding-bottom: 0px; margin-top: 30px;}
}


/* Case study single */
.list-1 {padding: 0;list-style: none; margin-top: 80px;margin-bottom: 0;}
.list-1 li div{border-top:2px solid #000;padding:30px 0 90px 0; position: relative;margin-left: 30px;}
.list-1 li div:before {content: ''; display: block;position: absolute;top: 0; margin-top: -7px;left: -30px;width: 10px;height: 12px; background-size: cover;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.392' height='12.001' viewBox='0 0 9.392 12.001'%3E %3Cpath id='Path_127' data-name='Path 127' d='M296.569-119.635h-4.7l4.7-12h4.7Z' transform='translate(-291.873 131.636)' fill='%23eb3f41'/%3E %3C/svg%3E ");}

@include media-breakpoint-up(md) {
	//.list-1 li:nth-last-child(-n+2) div {padding-bottom: 0}
}
@include media-breakpoint-down(md) {
	.list-1 {margin-top: 60px;}
	.list-1 li div{padding:40px 0; border-top:1px solid #000;}
}
@include media-breakpoint-up(xl) {
	.list-1 li div span{display: block;max-width:80%;}
}


.list-2 {padding: 0;list-style: none; margin-top: 80px;margin-bottom: 0;}
.list-2 li {border-bottom:2px solid #000;padding:2.6rem 0; position: relative; font-size:22px; color: $color-blue; line-height: 1.5}
.list-2 li:last-child {border-bottom:none}

@include media-breakpoint-up(lg) {
	.list-2.list-1-nopad-first li:first-child {padding-top: 0;}
}

@include media-breakpoint-down(md) {
	.list-2 {margin-top: 60px}
	.list-2 li {padding:1.5rem 0;border-width: 1px;font-size:18px;}
}


.social-right {padding: 0;list-style: none;margin:0 -12px;}
.social-right li{display: inline-block;}
.social-right a{ display: flex; width: 40px; height:40px; justify-content:center; align-items:center}

.social-right a path{transition:fill 0.4s}
.social-right a:hover path{fill:$color-blue}


/* Careers */

.list-inline {padding: 0;margin: 0;}
.list-inline li{ padding: 10px 0;padding: 22px 0; border-bottom: 1px solid #000000;  line-height: 1.5}

.list-inline li span{ flex:0 0 170px; font-weight: 700; color:$color-blue}
.list-inline.no-last li:last-child{border-bottom: none;}

@include media-breakpoint-up(lg) {
	.list-inline li:last-child{border-bottom: none;}
	.list-inline li{ display: flex; }
}


.item-careers {padding-top: 2.5rem; padding-bottom: 2.5rem;}
.row-careers {top: -2.7rem;position: relative;}

@include media-breakpoint-up(xxl) {
	.row-careers {top: -4.3rem;}
	.item-careers {padding-top: 4rem; padding-bottom: 4rem;}
}


/* Contact */
address, address p {font-size: 15px!important;}
address span{ display: block;}
address a{text-decoration: none;}

.touch address a {color:$color-blue}
address a[href^="mailto:"] {color:$color-blue}


/* About */
.careers-image-grid {}
.careers-image-grid .col:nth-child(2n) {margin-top: 70px;} 

.careers-image-item {position: relative;margin: 20px 10px;}
.careers-image-overlay {opacity: 0; transition:opacity 0.4s; position: absolute;top: 0;	right: 0;bottom: 0;	left: 0;z-index: 3; display: flex; flex-direction:column; padding: 30px 20px 20px 20px}
.careers-image-overlay .head { font-family: $font-secondary; color:$color-red; font-style: italic; font-size: 19px; line-height: 1.2}
.careers-image-overlay .line {flex:1; border-left: 1px solid #000;margin: 15px 0; display: none;}
.careers-image-overlay .members { font-size: 14px; font-weight: 700; display: none;}

.careers-image-item:hover .careers-image-overlay{opacity: 1}


@include media-breakpoint-down(xl) {
	.careers-image-overlay {display: none;}
}

@include media-breakpoint-down(xl) { 
	.careers-image-grid {margin-bottom: 4rem;margin-top: 2rem;}
	.careers-image-item {margin: 10px 5px;}
	
}
@include media-breakpoint-down(lg) { 
	//.careers-image-grid {margin-left: calc(-2.95rem - 5.4vw);margin-right: calc(-2.95rem - 5.4vw)}
}
@include media-breakpoint-down(sm) { 
	.careers-image-grid-mobile {margin-left: -5px;margin-right: -5px;}
	.careers-image-grid {margin-left: calc(-1.425rem - 2.1vw);margin-right: calc(-1.425rem - 2.1vw);}
}

/* Logos */
.row-logos-2 img {max-width: 100%;transform:scale(0.85);transform-origin: left;}
.row-logos-2 .logo-inner {margin: 20px 0;}

@include media-breakpoint-up(lg) { 
	.row-logos-2 .logo-inner {height: 100px; display: flex;align-items:center;}
}

@include media-breakpoint-down(md) {
	.row-logos-2 img {transform:scale(0.75)}
}


.row-logos-3 img {max-width: 100%;transform-origin: center; }


	.row-logos-3 > div {text-align: center;}
@include media-breakpoint-up(lg) {
	.row-logos-3 > div:nth-child(6n+1) { text-align: left;}
	.row-logos-3 > div:nth-child(6n+1) { text-align: left;}
}

@include media-breakpoint-down(lg) {
	.row-logos-3 img {transform:scale(0.75)}
	.row-logos-3 > div:nth-child(3n+1) { text-align: left;}
	.row-logos-3 > div:nth-child(3n+3) { text-align: right;}

	.row-logos-3 > div:nth-child(3n+1) img{transform-origin: left;}
	.row-logos-3 > div:nth-child(3n+3) img{transform-origin: right;}
}

.content-aria h2 {@include font-size(2rem);margin: 50px 0 30px 0; color: $color-red}
.content-aria h3 {@include font-size(1.7rem);margin: 30px 0 20px 0;}
.content-aria h4 {@include font-size(1.5rem);margin: 20px 0 15px 0;}

.content-aria ul {margin: 30px 0 20px 0;padding: 0;list-style: none; font-size: 17px;line-height: 1.8;}
.content-aria ul li{position: relative;padding-left: 30px;margin: 15px 0;}
.content-aria ul li ul li{padding-left: 30px;margin: 15px 0;}

.content-aria ul li:before {content: ''; display: block;position: absolute;top: 6px;left: 0;width: 10px;height: 12px; background-size: cover;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.392' height='12.001' viewBox='0 0 9.392 12.001'%3E %3Cpath id='Path_127' data-name='Path 127' d='M296.569-119.635h-4.7l4.7-12h4.7Z' transform='translate(-291.873 131.636)' fill='%23eb3f41'/%3E %3C/svg%3E ");}
.content-aria ul li:after {display: none;}

.content-aria a {color: $color-red; text-decoration: underline;}
.content-aria a:hover {color: #000; text-decoration: underline;}

.content-aria a.btn {text-decoration: none;max-width: 370px;width: 100%;}


.content-aria ol {margin: 30px 0 20px 0;padding: 0;list-style: none;font-size: 17px;line-height: 1.8;}
.content-aria ol li{position: relative;padding-left: 40px;margin: 15px 0;counter-increment: item;}
.content-aria ol li:before { content: counter(item, decimal-leading-zero) "."; padding-right: 30px; color: #EB3F41; top: 2px; position: absolute; font-weight: 700; left: 0; background-image: none; }
.table-small * {font-size: 14px;}

.content-aria img {max-width: 100%!important;height: auto!important;}


.right-bar h3 {font-size: 18px;margin-bottom: 3px;}
.right-bar p {font-size: 16px;margin-bottom: 30px;}
.nav-secondary {padding: 0;margin-bottom: 0;list-style: none;}
.nav-secondary li .nav-title {padding: 25px 0; border-bottom:1px solid #000; position: relative; font-size: 16px; transition:color 0.6s;line-height: 1.3;}
.nav-scroll li .nav-title {max-width:  calc(100% - 100px)}
.nav-secondary li .nav-title > div { display: inline-block;white-space: nowrap; transition: width .6s ease; }
.nav-secondary li .nav-title > div em { display: inline-block;font-style: normal; text-align: left;}
.nav-secondary li .nav-title > div em {}
//.nav-secondary li.active a .nav-title > div em {text-align: right;}

.nav-secondary li.active .nav-title {color: $color-red;}
.nav-secondary li .nav-title:before {content: ''; display: block;position: absolute;bottom: -2px;left: 0;width: 100%;height: 3px;background-color: $color-red;transform: scaleX(0);transform-origin: left; transition:transform 0.6s ease}
.nav-secondary li.active .nav-title:before {transform: scaleX(1);}

.nav-secondary li a:hover {color: inherit;}
.nav-secondary li a:hover .nav-title{color: $color-red;}

.nav-secondary li:last-child .nav-content p {margin-bottom: 0;}




/* nav-hover */
.nav-hover-holder {position: relative;}
.nav-hover.nav-secondary li .nav-title {@include font-size(1.3rem);}
.nav-hover .nav-title {position: relative;z-index: 2; cursor: pointer;}
.nav-hover .nav-content {@include font-size(1.4rem); pointer-events: none; opacity: 0; transition: opacity 0.2s ease; }
.nav-hover li.active .nav-content { z-index: 1; pointer-events: auto; opacity: 1; }



/* nav-height */
.nav-height {overflow: visible!important;}
.nav-height.nav-secondary li .nav-title {font-size: 16px; line-height: 1.3;}






/* Up */
@include media-breakpoint-down(xxl) {
    .nav-scroll li .nav-title {max-width:  calc(100% - 50px)}

}
@include media-breakpoint-up(md) {
    .nav-secondary li .nav-title > div {width: 0; text-align: right; }
    .nav-secondary li.active .nav-title > div {width: 100%!important; }

    .nav-secondary li .nav-title:after {content: ''; display: block;position: absolute;top: 50%; margin-top: -7px;left: 0;width: 10px;height: 12px; background-size: cover;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.392' height='12.001' viewBox='0 0 9.392 12.001'%3E %3Cpath id='Path_127' data-name='Path 127' d='M296.569-119.635h-4.7l4.7-12h4.7Z' transform='translate(-291.873 131.636)' fill='%23eb3f41'/%3E %3C/svg%3E ");}
    .nav-secondary li .nav-title:after {left: -30px;}

    .nav-hover li .nav-content {position: absolute; top: 0; left: 0; right: 0;padding-top: 23px; }
    .nav-hover li.active .nav-content {transition: opacity 0.4s ease 0.2s;}

    .nav-height .nav-content {min-height: 350px}
    .nav-height.nav-secondary li .nav-title:before {left: 0;}

}



@include media-breakpoint-up(xxl) {
    .nav-scroll li a .nav-title{padding-left: 30px;}
    .nav-scroll li .nav-title:after {left: 0px;}
}


/* Down */
@include media-breakpoint-down(xl) {
    .nav-hover.nav-secondary li .nav-title {@include font-size(1.1rem);}
    .nav-secondary li .nav-title {font-size: 14px;}

    .nav-scroll li .nav-title {max-width:  calc(100% - 50px)}

}
@include media-breakpoint-down(lg) {
    .nav-hover.nav-secondary li .nav-title {@include font-size(0.9rem);}




}
@include media-breakpoint-down(md) {
    .nav-hover.nav-secondary li .nav-title {@include font-size(1.3rem);}

    .nav-hover li .nav-content {display: none;}
    .nav-hover li .nav-content p{margin: 20px 0; font-size: 20px}
    //.nav-hover li.active .nav-content {max-height: 100%}

    .nav-secondary li .nav-title:after {content: ''; display: block;position: absolute;top: 50%; margin-top: -7px;right: 0;width: 13px;height: 7px; background-size: cover;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15.904' height='8.952' viewBox='0 0 15.904 8.952'%3E %3Cg id='Group_778' data-name='Group 778' transform='translate(-360.586 -2813.086)'%3E %3Cpath id='Path_421' data-name='Path 421' d='M0%2C0%2C6.538%2C6.538%2C0%2C13.076' transform='translate(375.076 2814.5) rotate(90)' fill='none' stroke='%23000' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E %3C/g%3E %3C/svg%3E "); transition:transform 0.4s}
    .nav-hover li.active .nav-title:after {transform:rotate(180deg)}

    .nav-height {height: auto!important}

}






main {max-width: 2000px;margin: 0 auto; background-color: #fff}

section, .section {@include padding(8rem 0);}

.section-small {@include padding(6rem 0);}

.container {@include padding(0 7rem);}
.container-inner {@include padding(0 6rem);}

.inner {/*max-width: 1560px;*/margin: 0 auto; width: 100%;}
.inner-lg {max-width: 1220px;margin: 0 auto; width: 100%;}
.inner-md {max-width: 1020px;margin: 0 auto; width: 100%;}
.inner-sm {max-width: 840px;margin: 0 auto; width: 100%;}


.inner-x {@include padding(0 6rem);}
.left-outdent {@include margin(0 0 0 -6rem);}
.right-outdent {@include margin(0 -6rem 0 0);}

//.inner-s {padding-left: 90px;}
//.inner-e {padding-right: 90px;}

/* Responsive down*/
@include media-breakpoint-down(xxl) {
	section, .section {@include padding(6rem 0);}
	.section-small {@include padding(4rem 0);}
	.container {@include padding(0 5rem);}
	.container-inner {@include padding(0 5rem);}

	.inner-x {@include padding(0 4rem);}
	.left-outdent {@include margin(0 0 0 -4rem);}
	.right-outdent {@include margin(0 -4rem 0 0);}
}

@include media-breakpoint-down(xl) {
	.container {@include padding(0 4rem);}
	.container-inner {@include padding(0 4rem);}

	.inner-s {padding-left: 60px;}
	.inner-e {padding-right: 60px;}
}

@include media-breakpoint-down(lg) {
	.container {@include padding(0 3rem);}
	.container-inner {@include padding(0 3rem);}
}

@include media-breakpoint-down(sm) {
	.container-inner {padding: 0;}

	.inner-x {padding: 0;}
	.left-outdent {margin: 0;}
	.right-outdent {margin: 0;}
	.inner-s {padding-left: 0;}
	.inner-e {padding-right: 0;}
}
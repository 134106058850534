@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
     /* IE10+ CSS styles go here */
     .header-home { overflow: hidden;}
     .header-home video {
	    position: absolute;
	    min-width: 100%;
	    min-height: 100%;
	    width: auto;
	    height: auto;
	    left: 50%;
	    top: 50%;
	    transform: translate(-50%, -50%);
	}

	.text-vertical {display: inline-block!important;}
	.text-vertical.d-lg-none {display: none!important;}
	.waterfall a .waterfall-right img {height: auto!important;min-height:100%}

	.scrub-parallax img, .scrub-parallax video { height: auto !important}
	.image-174.image-md-68.scrub-parallax img{ width: auto !important; height: 100%!important}

	.link-icon em {overflow: hidden;padding-right: 20px;}
	.row { display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; margin-right: calc(-1vw - 15px); margin-left: calc(-1vw - 15px); }
	.row.gx-0, .row.g-0{ margin-right: 0; margin-left: 0; }
	.row.gx-0 > .col, 
	.row.gx-0 > [class*="col-"],
	.row.row.g-0 > .col, 
	.row.row.g-0 > [class*="col-"] { padding-right: 0; padding-left: 0; }
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto { padding-right: calc(1vw + 15px); padding-left: calc(1vw + 15px); }

	@include media-breakpoint-up(sm) {
		.row.gx-0.gx-sm-5 {margin-right: calc(-0.5vw - 10px); margin-left: calc(-0.5vw - 10px);}
		.row.gx-0.gx-sm-5 > [class*="col-"] {padding-right: calc(0.5vw + 10px); padding-left: calc(0.5vw + 10px);}
	}

	.row-case.row-case-invert .logo img {-webkit-filter: invert(100%);    -o-filter: invert(100%);    -moz-filter: invert(100%);    -ms-filter: invert(100%);    filter: invert(100%);}

	.btn-social {overflow: hidden;}

	.row-case .col-case .logo {background-color: #fff}
	.bg-blue .row-case .col-case .logo {margin-bottom:0}
	.btn-social span {flex:auto}

}
.btn-down {display: flex; line-height: 1; text-decoration: none; color: inherit; @include font-size(1.7rem); white-space: nowrap;}
.btn-down:hover {color: inherit; }
.btn-down span {font-family: $font-secondary; font-weight: 800}
.btn-down div {position: relative;}
.btn-down div:after {content: ''; position: absolute;bottom: -8px;left: 0;height: 1px;background: #fff; width: 0; opacity: 0.4; transition:width 0.4s}
.btn-down:hover div:after {width: 100%;}
.btn-down em {margin-left: 20px;}
.btn-down svg { transition:transform 0.4s}
.btn-down:hover svg {transform:translateY(2px)}

.link-icon {display: flex; line-height: 1; text-decoration: none; color: $color-red; @include font-size(1.5rem); white-space: nowrap; padding: 20px 0 0 0;}
.link-icon:hover {color: inherit; }
.link-icon span {font-family: $font-secondary; font-weight: 800}
.link-icon div {position: relative;}
.link-icon div:after {content: ''; position: absolute;bottom: -8px;left: 0;height: 1px;background: #000; width: 0; opacity: 0.4; transition:width 0.4s}
.link-icon:hover div:after {width: 100%;}
.link-icon em {margin-left: 20px;}
.link-icon svg { transition:transform 0.4s}
.link-icon:hover svg {transform:translateX(5px)}

.link-icon#submit-contact-form svg path {stroke:$color-red}


.link-icon.small { font-size: 16px}
.link-icon.small.white {color: #fff}
.link-icon.small.white div:after {background: #fff;}
.link-icon.small.white svg path {stroke: #fff;}




.btn-js-hover div:after {left: auto;right: 0}

.btn {font-size: 16px; color: $color-red; text-decoration: none;display: inline-block; white-space: nowrap;width: 100%; max-width: 300px}
.btn:hover {color: $color-red;}
.btn div {display: flex;}
.btn span, .btn em {display: flex; align-items:center;line-height: 0}
.btn strong {font-weight: 400}
.btn span {
    border: 1px solid #000000;
    height: 80px;
    padding: 0 20px 0 35px;
    flex: 1;
}
.btn em{border:1px solid #000000;border-left: none;width: 80px;min-width: 80px;flex:0 1 80px}

.btn em { transition:background-color 0.4s; justify-content:center;}
.btn:hover em {background-color: #F3F4F9}


.link-red {color: $color-red; text-decoration: none;}
.link-red:hover {color: #fff;}

.btn-social {font-size: 16px; text-decoration: none;display: inline-block; white-space: nowrap;}
.btn-social div {display: flex;}
.btn-social span, .btn-social a {display: flex; align-items:center;line-height: 0}
.btn-social span { border: 1px solid #000000; height: 80px; padding: 0 35px 0 35px; flex: 1; }
.btn-social a { border:1px solid #000000;border-left: none;width: 80px;}
.btn-social a { transition:background-color 0.4s; justify-content:center;}
.btn-social a:hover {background-color: #F3F4F9}
.btn-social em {font-style: normal;}


.btn.w-100 {max-width: 100%}


@include media-breakpoint-up(xl) {
	.btn.btn-kaalgat {display: block; }
	.btn.btn-kaalgat span {border-top: none;border-left: none;border-right: none;padding-left: 0;min-width: auto;flex: 1; padding-right: 15px; width: 100%;height: 70px;}
	.btn.btn-kaalgat em{border-left: none;border-right: none; border-top:none;  width: 70px;}
}

@include media-breakpoint-up(lg) {
	.link-icon.small.white-lg {color: #fff}
	.link-icon.small.white-lg div:after {background: #fff;}
	.link-icon.small.white-lg svg path {stroke: #fff;}
}


@include media-breakpoint-down(lg) {
	.btn-down {@include font-size(1.5rem);}
	.btn span{height: 70px;padding: 0 30px; }
	.btn em{width: 70px;min-width: 70px;flex:0 1 70px}

	.btn-social span {height:70px; padding: 0 20px 0 20px;}
	.btn-social a {width:70px;}
}

@include media-breakpoint-down(md) {
}

@include media-breakpoint-down(sm) {
	.btn-down {display: flex; line-height: 1; text-decoration: none; color: inherit; @include font-size(1.2rem);}
	.btn {font-size: 16px;display: flex;}
	.btn div {width: 100%;}
	.btn span {justify-content:flex-start;flex: 1;}

	.btn-social em {display: none;}
}

/* Footer */
footer {padding:0 0 40px 0;}

.newsletter-form {max-width: 250px;}
.newsletter-form .float-label label {font-size: 15px; opacity: 0.5}
.newsletter-form .submit-holder {margin-left: -60px;}
.newsletter-form .float-label input[type="email"] {font-size: 14px!important}

.newsletter-form [type="submit"] {width: 60px;padding: 0; background-color: transparent;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.537' height='15.904' viewBox='0 0 23.537 15.904'%3E %3Cg id='Group_823' data-name='Group 823' transform='translate(-858.5 -6368.048)'%3E %3Cpath id='Path_470' data-name='Path 470' d='M0%2C0H21.3' transform='translate(859.5 6376)' fill='none' stroke='%23eb3f41' stroke-linecap='round' stroke-width='2'/%3E %3Cpath id='Path_11' data-name='Path 11' d='M1322.519%2C2012.541l6.538%2C6.538-6.538%2C6.538' transform='translate(-448.02 4356.921)' fill='none' stroke='%23eb3f41' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'/%3E %3C/g%3E %3C/svg%3E ");background-position: center right;background-repeat: no-repeat;border-bottom:none;transition:filter 0.4s;}
.newsletter-form [type="submit"]:hover, .newsletter-form [type="submit"]:focus {filter: grayscale(1); }
.newsletter-form input:-webkit-autofill,
.newsletter-form input:-webkit-autofill:hover, 
.newsletter-form input:-webkit-autofill:focus, {border-bottom: 1px solid $color-red;   -webkit-text-fill-color: #000;  background-color: transparent;  -webkit-transition: background-color 5000s ease-in-out 0s;  transition: background-color 5000s ease-in-out 0s;  -webkit-box-shadow: 0 0 0 80px #fff inset !important;}

.nav-footer {display: flex;list-style: none;margin: 0;padding: 0;}
.nav-footer li {display: flex;align-items: center;}
.nav-footer li a { color: $color-red; font-size: 14px; display: flex; align-items: center; padding: 20px; height: 100%; text-decoration: none; transition: all 0.4s; white-space: nowrap; position: relative; }
.nav-footer li:first-child a {padding-left: 0;}
.nav-footer li:last-child a {padding-right: 0;}
.nav-footer li a em {font-style: normal; overflow: hidden;}
.nav-footer li a span { position: relative; display: inline-block; transition: transform 0.3s; }
.nav-footer li a span::before { position: absolute; top: 100%; content: attr(data-hover); text-decoration: underline;text-underline-position: under; color: $color-red; transform: translate3d(0, 0, 0); }
.nav-footer li:hover a em span, 
.nav-footer li:focus a em span  { -webkit-transform: translateY(-100%); -moz-transform: translateY(-100%); transform: translateY(-100%); }


.privacy-text {font-size: 14px; color:rgba(0,0,0,0.5); position: relative;top: 1px;}

.nav-privacy {display: flex;list-style: none;margin: 0;padding: 0; color:rgba(0,0,0,0.5)}
.nav-privacy li {font-size: 14px;}
.nav-privacy li a{color:rgba(0,0,0,1); text-decoration: none;display: flex; align-items: center; font-size: 14px;}
.nav-privacy li a:hover{color:$color-red; text-decoration: none;}
.nav-privacy li a:after{content:'|'; display: inline-block;margin: 0 10px;color:rgba(0,0,0,0.5);}
.nav-privacy li:last-child a:after{display: none;}
.nav-privacy li a em {font-style: normal; overflow: hidden;}
.nav-privacy li a span { position: relative; display: inline-block; transition: transform 0.3s; }
.nav-privacy li a span::before { position: absolute; top: 100%; content: attr(data-hover); text-decoration: underline;text-underline-position: under; color: $color-red; transform: translate3d(0, 0, 0); }
.nav-privacy li:hover a em span, 
.nav-privacy li:focus a em span  { -webkit-transform: translateY(-100%); -moz-transform: translateY(-100%); transform: translateY(-100%); }

.copy-text {margin: 50px 0 0 0;font-size: 14px;color:rgba(0,0,0,0.5); text-align: right;}

@include media-breakpoint-down(md) {
  .newsletter-form {max-width: 100%;}
  .nav-footer-holder {width: 100%;}
  .nav-privacy {justify-content:center;}
  .nav-footer {max-width: 430px;margin: 0 auto;}
  .nav-footer li a {padding: 10px 20px;}

  .copy-text {margin: 20px 0 0 0;text-align: center;}

}

@include media-breakpoint-down(sm) {
  .nav-footer {justify-content:space-between;}
  .nav-privacy { font-size: 14px;font-size: 12px }
  .nav-footer li a {padding: 10px 0; font-size: 12px}
}
.font-secondary {
    font-family: $font-secondary
}
p, ul li{font-size: 17px; line-height: 1.8}
small, .small {font-size: 16px}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    line-height: 1.3;
    color:#000000;
    font-weight: 800;
}

blockquote {
    color: #4452E0;
    @include font-size(1.7rem);
    font-weight: 600;
    max-width: 620px;
    padding-left: 30px;
    border-left: 2px solid $body-color;
    margin: 60px 0;
}


.lead-big {
    font-weight: 300;
    @include font-size(2.4rem);
        line-height: 1.5;
}

.lead {
    font-weight: 300;
    @include font-size(2.05rem);
        line-height: 1.3;
}

.lead strong {font-family: $font-secondary; font-style: italic; font-weight: 400}

.lead-2 {
    font-size: 22px;
        line-height: 1.5;
}

.lead-3 {
    font-size: 22px;
        line-height: 1.5;
}

.lead-4 {
    font-size: 26px;
    font-weight: 300;
        line-height: 1.5;
}
.lead-4 strong {font-family: $font-secondary; font-style: italic; font-weight: 400}



h1,
.h1 {
    @include font-size(2.6rem);
    
}

h2,
.h2 {
    @include font-size(2.6rem);
    line-height: 1.2;
    font-weight: 300
}

h3,
.h3 {
    @include font-size(2.4rem);
}

h4,
.h4 {
    @include font-size(1.9rem);
}

h5,
.h5 {
    @include font-size(1.7rem);
}

h6,
.h6 {
    @include font-size(1.4rem);
    line-height: 1.3;
}


h1.big,
.h1.big {
    @include font-size(3.4rem);
}

@media (min-width: 1601px) {
    h1.big, .h1.big {@include font-size(4.4rem);    }
}


.h5 span {font-family: $font-secondary; font-style: italic; font-weight: 400}


@include media-breakpoint-up(xl) {
    .small-xl {font-size: 16px;}
    .bold-xl {font-weight: 700!important}
}


@include media-breakpoint-down(lg) {
    .lead-3 {font-size: 17px}
}
@include media-breakpoint-down(md) {
    .lead-2 {font-size: 20px}
    .lead-3 {font-size: 20px}
    .lead-4 {font-size: 22px;}
}

@include media-breakpoint-down(xxl) {
    h2,
    .h2 {
        @include font-size(2.4rem);
    }

    h3,
    .h3 {
        @include font-size(2.2rem);
    }

    h4,
    .h4 {
        @include font-size(1.7rem);
    }

    h5,
    .h5 {
        @include font-size(1.5rem);
    }

    h6,
    .h6 {
        @include font-size(1.2rem);
    }
    .lead-big {
        @include font-size(2rem);
    }
    .lead {
        @include font-size(1.8rem);
    }

}


@include media-breakpoint-down(sm) {
    .fw-md-regular-d {font-weight: 400}

}
@include media-breakpoint-down(sm) {
    small {font-size: 13px}
    h1,
    .h1 {
        @include font-size(2.4rem);
       
    }
    h1.big,
    .h1.big {
        font-size: 38px;
        line-height: 1.1;
    }
    h2,
    .h2 {
        @include font-size(2rem);
        font-weight: 400
    }

    .lead-big {
        font-size: 24px
    }
    .lead {
        font-size: 22px;
        line-height: 1.6
    }
}

@include media-breakpoint-up(lg) {
    h1.big-desktop,
    .h1.big-desktop {
        @include font-size(3.4rem);
    }
}



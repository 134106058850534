/* Waterfall */
.waterfall              .waterfall-item.white, .waterfall .waterfall-item.white .h6 {color: #fff}
.waterfall              .waterfall-item.white .waterfall-icon span {border-color:#fff}
.waterfall              .waterfall-item.white .waterfall-icon svg path {stroke:#fff}
.waterfall              .waterfall-item.white .waterfall-icon span:after {background-color: #fff}


body .waterfall-open.waterfall a      .waterfall-right img{transform:translateX(0)!important; }
//body .waterfall-open.waterfall:hover a{transform:scale(1); transition:transform 0.4s; display: block;}
//body .waterfall-open.waterfall:hover a:hover{transform:scale(1.05);}



@include media-breakpoint-up(lg) {
    .waterfall              {display: flex;flex-wrap:wrap}
    .waterfall a            {width: 50%; display: flex; position: relative; text-decoration: none; color: #000000; overflow: hidden;}
    .waterfall a:last-child {width: auto;overflow: visible;}

    .waterfall a            .waterfall-left {@include rfs(8rem, width); flex: none; position: relative;z-index: 4;left: 0;top: 0;bottom:0;display: flex;transition:width 0.4s; }
    .waterfall a:hover      .waterfall-left {@include rfs(26rem, width);}
    .waterfall a:last-child .waterfall-left{@include margin(0 -8rem 0 0); left: auto;right: 0; position: absolute; }

    .waterfall a            .waterfall-left-1 {@include rfs(3rem 0 3rem 3rem, padding); flex: none; display: flex; flex-direction: column;    width: 100%;}
    .waterfall a            .waterfall-icon {@include rfs(0 0 3.2rem 0, padding);display: flex; align-items:center;position: relative;left: 8px;white-space: nowrap;}
    .waterfall a            .waterfall-icon svg{flex:none}
    .waterfall a            .waterfall-icon span {margin-left: 40px;font-size: 16px; opacity: 0; transform:translateX(-20px); transition:opacity 0.4s 0s, transform 0.4s 0s; position: relative; }
    .waterfall a            .waterfall-icon span:after {content: ''; position:absolute;bottom: 0;right: 0;width: 100%;height: 1px;background-color: #000;transition:width 0.4s 0s,}
    .waterfall a            .waterfall-icon span:hover:after {width: 0;}
    
    .waterfall a:hover      .waterfall-icon span {opacity: 1;transform:translateX(0);transition:opacity 0.4s 0.2s, transform 0.4s 0.2s}

    .waterfall a            .waterfall-left .waterfall-content {font-size: 16px; @include rfs(20rem, width);@include rfs(3rem 0 0 0, padding);  opacity: 0; transform:translateX(-20px); transition:opacity 0.4s 0s, transform 0.4s 0s}
    .waterfall a            .waterfall-left .waterfall-content p {line-height: 1.5; }
    .waterfall a:hover      .waterfall-left .waterfall-content {opacity: 1;transform:translateX(0);transition:opacity 0.4s 0.2s, transform 0.4s 0.2s}


    .waterfall a            .waterfall-right {/* clip-path: polygon(0% 0%, 77% 0%, 119% 100%, 0% 100%); */transition:transform 0.4s;position: absolute;top: 0;bottom: 0;left: 0;right: 0;}
    .waterfall a:hover      .waterfall-right {transform:translateX(11rem); transition:transform 0.4s;}

    .waterfall a            .waterfall-right img{transform:translateX(5rem); transition:transform 0.4s;object-fit: cover;height: 100%;width: 100%;}
    .waterfall a:hover      .waterfall-right img{transform:translateX(8rem); }
    .waterfall a            .waterfall-right:after { content: ''; display: block; position: absolute; top: -70%; bottom: 0; width: 100%; right: -85%; background-color: #fff; transform: rotate(-21deg); }


    body .waterfall-2-col.waterfall a            {width: 100%;}
    body .waterfall-2-col.waterfall a:last-child {width: auto;}

    body .waterfall-open.waterfall a      .waterfall-left {width: 22rem;}
    body .waterfall-open.waterfall a      .waterfall-icon span {opacity: 1;transform:translateX(0);}
    body .waterfall-open.waterfall a      .waterfall-left .waterfall-content {opacity: 1;transform:translateX(0);}
    body .waterfall-open.waterfall a      .waterfall-right {width: calc(100% - 36rem);transform: translateX(22rem);}
    body .waterfall-open.waterfall a      .waterfall-left .waterfall-content {width: 18rem;}

}


@include media-breakpoint-up(xl) {
    .waterfall a            .waterfall-left {@include rfs(9rem, width);}
    .waterfall a:last-child .waterfall-left{@include margin(0 -9rem 0 0); }
    .waterfall a            .waterfall-left-1 {@include rfs(4rem 0 4rem 4rem, padding);}
    .waterfall a            .waterfall-left .waterfall-content {font-size: 17px; @include rfs(18rem, width);@include rfs(3.6rem 0 0 0, padding);}

    .waterfall a:hover      .waterfall-right {transform:translateX(12rem);}
    .waterfall a            .waterfall-right img{transform:translateX(6rem);}
    .waterfall a:hover      .waterfall-right img{transform:translateX(10rem);}
   
    body .waterfall-open.waterfall a      .waterfall-left {@include rfs(22rem, width);}
    body .waterfall-open.waterfall a      .waterfall-right {width: calc(100% - 35rem);transform:translateX(22rem);}
    body .waterfall-open.waterfall a:last-child .waterfall-left{@include margin(0 -9rem 0 0); }
    body .waterfall-open.waterfall a        .waterfall-left .waterfall-content {width: 15rem; }
}

@include media-breakpoint-up(xxl) {
    .waterfall a            .waterfall-left {@include rfs(13rem, width);}
    .waterfall a:hover      .waterfall-left {@include rfs(29rem, width);}
    .waterfall a:last-child .waterfall-left{@include margin(0 -13rem 0 0); }
    .waterfall a            .waterfall-left-1 {@include rfs(5.6rem 0 5.6rem 5.6rem, padding);}
    .waterfall a            .waterfall-right img{transform:translateX(5rem);}
    .waterfall a:hover      .waterfall-right img{transform:translateX(8rem); }
    .waterfall a            .waterfall-left .waterfall-content {font-size: 18px; @include rfs(19rem, width);@include rfs(3rem 0 0 0, padding); }

    body .waterfall-open.waterfall a      .waterfall-left {@include rfs(29rem, width);}
    body .waterfall-open.waterfall a        .waterfall-left .waterfall-content {width: 20rem; }
    body .waterfall-open.waterfall a      .waterfall-right {width: calc(100% - 45rem); transform:translateX(29rem);}
    body .waterfall-open.waterfall a:last-child .waterfall-left{@include margin(0 -13rem 0 0); }
   

}




@include media-breakpoint-down(lg) {
    .waterfall              {display: flex;flex-wrap:wrap;}
    .waterfall a            {width: 33.3333%; }
    .waterfall a img        {width: 100%; max-width: 100% }
    .waterfall a            .waterfall-left {display: flex;justify-content: center; }
    .waterfall a            .waterfall-left .waterfall-content {display: none;}
    .waterfall a            .waterfall-icon {@include rfs(0 0 3.2rem 0, padding);}
    .waterfall a            .waterfall-icon span {display: none;}
    .waterfall a            .waterfall-left-1 {@include rfs(6rem, padding);}
    .waterfall a            .waterfall-left-1 .text-vertical{height: 240px;}

    .waterfall a            .waterfall-right {position: relative;top: 0;bottom: 0;left: 0;right: 0;padding-bottom: 100%;}
    .waterfall a            .waterfall-right img{position: absolute; height: 100%;width: 100%;object-fit: cover;}

    body .waterfall-open.waterfall a {width: 50%; }
}



@include media-breakpoint-down(lg) {
    .waterfall              {@include margin(0 -8rem);}
}

@include media-breakpoint-down(sm) {
    .waterfall              {@include margin(0 -3rem);}
}
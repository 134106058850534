.lity-spacer {height: 15vh}

.lity-inner video {max-width: 100%;max-height: 70vh; display: block;}

.lity-share span {display: inline-block;padding:0 15px 0 18px; font-size: 16px; font-weight: 700}
.lity-share ul{ display: flex;list-style: none;padding: 0;margin: 0;}
.lity-share ul li a {display: inline-block; padding: 18px;transform:scale(1);transition:transform 0.4s}
.lity-share ul li a:hover {transform:scale(1.5)}


@include media-breakpoint-down(xl) {
  .lity-inner {max-width: 90%;margin: 0 auto;}
  .lity-close {max-width: 90%;margin: 0 auto;left: 0;}
}

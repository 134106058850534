.slider-main .carousel-cell {width: 100%; min-height: 100%; display: flex; justify-content:center; flex-direction:column }

.before-load {
    overflow: hidden;
}

.body-loaded .before-load {
    height: auto!important;
}

@media (min-width: 1601px) {
    //h1.big, .h1.big {max-width: 80%!important }
}

.gallery-items {position: relative;}

.gallery-item {}
.gallery-item .gallery-contents {transition:background-color 0.4s; cursor: pointer;}


.gallery-item .gallery-contents:hover {background-color: $color-pink} 
.gallery-item.active .gallery-contents {background-color: $color-blue; color:#fff}
.gallery-item.active .gallery-contents:after{background-color: $color-blue;}

.gallery-item.active h2 {font-family: $font-secondary; font-style: italic;}

.gallery-item .gallery-contents {padding-top: 3.5rem!important; padding-bottom: 3.5rem!important; position: relative;}

.gallery-item .gallery-contents:after, .gallery-items:after{transition:background-color 0.4s;content: ''; position: absolute;bottom: 0;width: 100%; left: 0;right: 0; background-color: #B2B2B2;height: 1px}

.gallery-item .gallery-contents p {font-size: 15px;line-height: 1.3;}
.gallery-item .gallery-contents h2 {line-height: 1.2;}
.gallery-item .link-icon div:after {background-color: #fff}

.gallery-expander-contents {margin: 0 auto;color: #fff;}


@include media-breakpoint-up(lg) {
	.gallery-item:nth-child(odd) .gallery-contents.container {padding-right: 0;} 
	.gallery-item:nth-child(odd)  .gallery-contents .inner-x {padding-right: 3.5rem;}

	.gallery-item:nth-child(even) .gallery-contents.container {padding-left: 0;}
	.gallery-item:nth-child(even)  .gallery-contents .inner-x {padding-left: 3.5rem;}

	.gallery-item:nth-child(even)  .gallery-expander-contents .expander-content {order:2}
	.gallery-item:nth-child(even)  .gallery-trigger-close {text-align: right;}

	.gallery-item:nth-child(even) .gallery-expander-contents .expander-content {padding: 0 0 0 3.5rem!important; }
	.gallery-item:nth-child(odd) .gallery-expander-contents .expander-content {padding: 0 3.5rem 0 0!important; }

	.gallery-expander-contents {padding-top: 3.5rem!important; padding-bottom: 3.5rem!important;}
}
@include media-breakpoint-down(lg) {
	.gallery-expander-contents {padding-top: 1.5rem!important; padding-bottom: 3.5rem!important;}

}
@include media-breakpoint-down(md) {
	//.gallery-item:nth-child(odd)  .gallery-contents .inner-x {padding-left:0!important; padding-right: 0!important;}
	//.gallery-item:nth-child(even)  .gallery-contents .inner-x {padding-left:0!important; padding-right: 0!important;}

	.gallery-item .gallery-expander-contents .gallery-trigger-close { text-align: right;}

	.gallery-item .gallery-contents .inner-x {padding-left: 0!important;padding-right: 0!important;}

}

@include media-breakpoint-between(lg, xl) {
	.gallery-item .gallery-contents {min-height: 310px;display: flex;align-items: center;width: 100%;}
	.gallery-item .gallery-contents .inner-x {width: 100%;}
}

.gallery-expander { position: absolute; z-index: 3; left: 0; right: 0; overflow: hidden; max-height: 0; transition: max-height 500ms ease; }

.gallery-contents { position: relative; }
.gallery-contents:after { content: ''; display: block; position: absolute; bottom: -21px; left: 0; right: 0; width: 10px; height: 0; margin: 0 auto; transition: border-bottom-width 500ms ease; border-left: 10px solid rgba(0, 0, 0, 0); border-right: 10px solid rgba(0, 0, 0, 0); border-bottom: 0px solid  #636366; }




.gallery-expander-contents div.close {font-size: 14px;text-decoration: underline; cursor: pointer; text-underline-position: under; color: #fff}
.gallery-expander-contents div.close:hover {text-decoration: none; }




.gallery-item .link-icon svg {
    transition: transform 0.4s 0.2s;
    transform-origin: center center;
    display: inline-block;
    padding-right: 0;
}
.gallery-item.active .link-icon svg {transform: rotate(180deg);}



@include media-breakpoint-down(sm) {
	.expander-image {margin: 0 calc(-1.425rem - 2.1vw);}
	.gallery-item .gallery-contents {padding-top: 2rem!important; padding-bottom: 2rem!important;}
	.gallery-item:nth-child(odd)  .gallery-contents .inner-x {padding-right: 2rem;}
	.gallery-item:nth-child(even)  .gallery-contents .inner-x {padding-left: 2rem;}
}



.bg-red {background-color: $color-red!important}
.bg-green {background-color: $color-green!important}
.bg-gray {background-color: $color-gray!important}
.bg-blue {background-color: $color-blue!important}
.bg-cyan {background-color: $color-cyan!important}
.bg-pink {background-color: $color-pink!important}
.bg-purple {background-color: $color-purple!important}

.bg-light {background-color: rgba(46,48,129,0.05) !important}
.bg-grey {background-color:  #B2B2B2 !important}
.bg-dark-grey {background-color:  #707070 !important}


.color-white {color:  #fff!important}
.color-red {color: $color-red!important}
.color-blue {color: $color-blue!important}
.color-pink {color:$color-pink!important}

@include media-breakpoint-up(sm) {}
@include media-breakpoint-up(md) {}
@include media-breakpoint-up(lg) {
	.bg-blue-lg {background-color: $color-blue!important}
	.bg-red-lg {background-color: $color-red!important}
	.bg-green-lg {background-color: $color-green!important}
	.bg-purple-lg {background-color: $color-purple!important}

	.color-white-lg {color:  #fff!important}
	.color-red-lg {color: $color-red!important}

}
@include media-breakpoint-up(xl) {}
@include media-breakpoint-up(xxl) {}


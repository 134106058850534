::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #ccc;
    font-weight: 400;
    font-size: 14px;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: #ccc;
    font-weight: 400;
    font-size: 14px;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: #ccc;
    font-weight: 400;
    font-size: 14px;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: #ccc;
    font-weight: 400;
    font-size: 14px;
}

input,
textarea,
select {
    @include transition(all .4s ease-in-out)
}

form button {background-color: transparent; border:none;padding-bottom: 20px!important;}




input[type="text"],
input[type="tel"],
textarea,
input[type="submit"],
form button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type="text"]:focus,
input[type="tel"]:focus,
input[type="file"]:focus,
textarea:focus,
input[type="submit"]:focus {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    box-shadow: none;
}

/* IE11 */
select::-ms-expand {
  display: none;
}


#apply-now input[type="submit"] {
    border-radius: 0px;
    border: none;
    padding: 17px 60px 15px 60px;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #000000;
    background-color: #fff;
    transition: background-color 0.4s
}

#apply-now input[type="submit"]:hover {    background-color: #F3F4F9;}

#apply-now input[type="submit"]:focus {
    color: #fff;
    background-color:#000;
}


.float-label {
    position: relative;
    /* margin-bottom: 20px; */
}

.float-label label {
    cursor: auto;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 24px;
    margin-bottom: 0;
    font-size: 17px;
    display: inline-block;
    padding: 0;
    background-color: rgba(255, 255, 255, 0);
    -moz-transition: opacity 0.2s, top 0.2s, font-size 0.2s;
    -o-transition: opacity 0.2s, top 0.2s, font-size 0.2s;
    -webkit-transition: opacity 0.2s, top 0.2s, font-size 0.2s;
    transition: opacity 0.2s, top 0.2s, font-size 0.2s;
    color: #121212;
    pointer-events: none;
    line-height: 1.5;
}

.float-label label.active {
    top: 6px;
    font-size: 14px;
    width: auto;
    opacity: 0.5
}




.float-label input[type="text"],
.float-label input[type="email"],
.float-label input[type="date"],
.float-label input[type="password"],
.float-label input[type="tel"],
.float-label input[type="search"],
.float-label input[type="number"],
.float-label select {
    height: 65px !important;
    line-height: 26px !important;
    padding: 10px 0 0 0 !important;
    font-size: 16px !important;
    background-color: transparent;
    color: #000 !important;
    min-width: 140px;
    transition: border 0.3s;
    width: 100%;
}

.float-label input[type="text"]:hover,
.float-label input[type="email"]:hover,
.float-label input[type="number"]:hover,
.float-label input[type="date"]:hover,
.float-label input[type="password"]:hover,
.float-label input[type="tel"]:hover,
.float-label textarea:hover,
.float-label button:hover,
.float-label select:hover {}

.float-label input[type="text"]:focus,
.float-label input[type="email"]:focus,
.float-label input[type="number"]:focus,
.float-label input[type="date"]:focus,
.float-label input[type="password"]:focus,
.float-label input[type="tel"]:focus,
.float-label input[type="number"]:focus,
.float-label textarea:focus,
.float-label button:focus,
.float-label select:focus {
    outline: none;
    border-bottom: 1px solid $color-red!important;
    box-shadow: none;
}

textarea.form-control {
    border: 1px solid $body-color;
    height: 130px;
    font-family: 'Poppins', sans-serif;
    font-size: 17px;
    color: $body-color !important;
}


textarea.form-control:focus {
    border: 1px solid $color-red!important;
}

.float-label label.textarea-label {
    position: static;
    margin-bottom: 15px;
}

.float-label label.textarea-label.active {
    font-size: 17px;
    opacity: 1
}



.float-label select {
    -moz-appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    transition: all 0.3s;
    z-index: auto !important;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6.832' height='4.123' viewBox='0 0 6.832 4.123'%3E %3Cg id='icon-down' transform='translate(-944.092 -348.606)'%3E %3Cpath id='Path_78' data-name='Path 78' d='M11122.549%2C2069.012l3.063%2C3.063%2C3.063-3.062' transform='translate(-10178.104 -1720.052)' fill='%23fff' stroke='%23121212' stroke-width='1'/%3E %3C/g%3E %3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 12px;
    margin-bottom: 0;
}



@include media-breakpoint-down(lg) {
    .float-label label {
        font-size: 15px;
    }
}


/** Validation **/


em.error {
    font-style: normal;
    color: $color-red;
    font-size: 12px;
    position: absolute;
    bottom: -28px;
    display: block;
    padding-top: 3px;
    padding-bottom: 5px;
    opacity: 0.8
}



/* Checkbox */



input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}
input[type="checkbox"] + label {
  position: relative;
  cursor: pointer;
   padding: 10px 30px;
    display: block;
    font-size: 15px;
}
input[type="checkbox"] + label:before {
  content: '';
  display: inline-block;
  vertical-align: text-top;
  width: 18px;
  height: 18px;
  background: rgba(255, 255, 255,1);
  border: 1px solid #000000;
  position: absolute;
  top: 10px;
  left: 0px;
}

input[type="checkbox"] + label:after {
  content: '';
  position: absolute;
  left: 3px;
  top: 13px;
  width: 12px;
  height: 12px;
  transition:all 0.1s
}
input[type="checkbox"]:hover + label:after {
  //background: rgba(183,190,193,0.45)
}
input[type="checkbox"]:checked + label:after {
  background: $color-red;
}




/* Loader */
.ajax-loader,
.ajax-loader:after {
    border-radius: 50%;
    width: 16px;
    height: 16px;
}

.ajax-loader {
    display: none;
    position: absolute;
    z-index: 4;
    top: 50%;
    margin-top: -8px !important;
    right: 4px;
    background-image: none !important;
    font-size: 10px;
    /* position: relative!important; */
    text-indent: -9999em !important;
    border-top: 2px solid rgba(0, 0, 0, 0.2) !important;
    border-right: 2px solid rgba(0, 0, 0, 0.2) !important;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2) !important;
    border-left: 2px solid #000 !important;
    transform: translateZ(0) !important;
    animation: loading 1.1s infinite linear !important;
}


#apply-now .ajax-loader {right: 15px;border-left: 2px solid #fff !important;}

.form-submitting {opacity: 0.5; pointer-events:none}
.form-submitting .ajax-loader {display: inline-block!important;}
.form-submitting button em {opacity: 0!important}
.form-submitting .newsletter-submit {opacity: 0!important}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border-bottom: 1px solid $color-red;
    background-color: transparent;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0 80px white inset !important;
}


/* File upload */


input[type="file"] {padding: 20px;background-color: rgba(46,48,129,0.05) !important; border:none;font-size: 16px !important;border: 1px solid #000;}
input[type="file"]:focus { border: 1px solid $color-red;}


.form-cont-holder {
    position: absolute !important;
    left: -9999px !important;
    top: -9999px !important;
}

#newsletter-reveal {
    display: none;
    font-style: normal;
    position: absolute;
    bottom: -30px;
    font-size: 12px;
    bottom: -28px;
    padding-top: 5px;
    padding-bottom: 5px;
    opacity: 0.8;
}

#newsletter-reveal.display-it {display: block;}


/* CF 7 */
input.wpcf7-not-valid, select.wpcf7-not-valid, textarea.wpcf7-not-valid  {
    border-color: $color-red!important;
}


.wpcf7-not-valid-tip {
    display: none!important
}


div.wpcf7-response-output {
    height: 0;
    text-align: left;
    margin: 0 0 0 0 !important;
    padding: 0 !important;
    border: 0 !important;
    font-size: 14px !important;
    clear: both;
    border-radius: 0;
    color: #d25b5b;
    position: relative;
    bottom: -10px;
}
.contact-form div.wpcf7-response-output {}

div.wpcf7-validation-errors { color: #d25b5b !important; }
div.wpcf7-mail-sent-ok { color: #398f14 !important; }


.wpcf7 form.sent .wpcf7-response-output {color: #006c0a;}


.row-case {top: -3rem;position: relative; }
.row-case .col-case {padding-top:3rem; padding-bottom:3rem; text-align: center;transition: background-color 0.4s;text-decoration: none; display: block;}

.row-case.row-case-invert{@include padding-top(5rem);@include padding-bottom(4rem);}
.row-case.row-case-invert {top: 0;}

.row-case .col-case:hover {background-color: rgba(46,48,129,0.05);color: inherit;}

.row-case .col-case {counter-increment:item -1;}
.row-case .col-case .small {font-size: 16px; font-family: $font-secondary}
.row-case .col-case .number:before {text-align: right;display: block; content: "#"counter(item, decimal-leading-zero);}

.row-case .col-case .logo {display: inline-block;width: 100%; height: 60px; display: flex; align-items:center; justify-content:center}
.row-case.row-case-invert .logo{margin-bottom: 50px;}

.row-case .col-case .image {position: relative;margin-bottom: 60px;}
.row-case .col-case .image .image-absolute {padding-bottom: 60%;}
// .row-case .col-case .image img {transition:transform 0.4s, filter 0.4s;transform:scale(1.1)}
// .row-case .col-case:hover .image img {transform:scale(1)}
.row-case .col-case .play {position: absolute;top: 0;bottom: 0;left: 0;right: 0; display: flex;align-items:center; justify-content:center} 
.row-case .col-case .description {max-width: 255px;margin: 0 auto;}
.row-case .col-case h3 {font-size: 18px;margin-bottom: 3px;}
.row-case .col-case p {font-size: 16px;margin-bottom: 30px;}
.row-case .col-case p:last-child {margin-bottom: 0;}



.row-case.row-case-invert .col-case, .row-case-invert .col-case h3, .row-case-invert .col-case p{color:#fff; }
.row-case.row-case-invert .col-case:hover {background-color: #2F3495}
.row-case.row-case-invert .logo img{filter: invert(1);}

@include media-breakpoint-up(xl) {
    .row-case .col-case {border-right:1px solid #000000!important; position: relative;}

    .row-case .col-case:nth-child(-n+3):after {content:''; position: absolute;right: 0;top: 0;display: block;right: -1px; background-color: #fff;width: 1px;height: 3rem;z-index: 2}
    .row-case.row-case-invert .col-case:nth-child(-n+3):after {display: none;}

    .row-case .col-case:nth-child(3n) {border-right:none!important}

    .row-case.row-case-invert .col-case {border-color:#2F3495!important}
}



@include media-breakpoint-down(xxl) {
    .row-case.row-case-invert.col-case {@include padding-top(4rem);@include padding-bottom(2rem); }
}
@include media-breakpoint-down(lg) {
    .row-case .col-case {margin-top: 0;}
}



@include media-breakpoint-between(md, xl)  {
    .row-case {display: block;}
    .row-case .col-case { width: 100%; display: flex;  text-align: left;}
    .row-case.row-case-invert .col-case {@include padding-top(5rem);@include padding-bottom(5rem);}

    .row-case .col-case .image{margin-bottom: 0;flex: 0 0 auto;width: 33.33333%;padding-right: 2rem;}
    .row-case .col-case .image .image-absolute {padding-bottom: 100%;}
    .row-case .col-case .description{flex: 0 0 auto;width: 66.66667%;min-width: 400px; margin: 0; padding-left: 2.5rem;}

    .row-case .col-case .logo {width: auto;margin-bottom: 40px; justify-content:flex-start}
}

@include media-breakpoint-down(lg) {
    .row-case .col-case {}
    .row-case .col-case .image{ }
    //.row-case .col-case:nth-child(2n) {background-color: #2F3495}
}




@include media-breakpoint-down(md) {
    .row-case {display: block;}
    .row-case .col-case {@include padding-top(4rem);@include padding-bottom(4rem);}

    .row-case .col-case .logo {margin-bottom: 40px;}
    .row-case .col-case .image{margin-bottom: 30px;}
    
}

@include media-breakpoint-down(sm) {
    .row-case.row-case-invert{padding-top: 0;padding-bottom: 0;}
    .row-case {display: block;}
    .row-case .col-case {@include padding-top(4rem);@include padding-bottom(4rem);}

    .row-case .col-case {width: 100%;border-bottom: 1px solid  #DCDDE2;}
    .row-case.row-case-invert .col-case {border-bottom: 1px solid #2F3495;}


}




/* Logo */

.nav-holder {max-width: 2000px;margin: 0 auto; background-color: rgba(255,255,255,1);z-index: 99; position: fixed;left: 0;right: 0;transition:background-color 0.4s, transform 0.6s}
.nav-holder .logo {width: 135px; transition:opacity 0.4s, transform 0.4s}
.nav-holder .logo svg {width: 100%;}

/* Overlay */
.nav-overlay { cursor: pointer; position: fixed; top: 0; right: 0; left: 0; bottom: 0; visibility: hidden; background-color: rgba(36, 41, 143, 0.9);webkit-backdrop-filter: saturate(180%) blur(8px); backdrop-filter: saturate(180%) blur(8px); transition: opacity 0.3s 0s, visibility 0s 0.3s; z-index: 8; opacity: 0;}



.nav-invert.nav-holder {background-color: rgba(255,255,255,0);}
.nav-invert.nav-holder .logo svg path {fill:#fff; transition:fill 0.2s}

.nav-invert.nav-holder.nav-moved-background .nav-primary > li > a {color:$color-red}
.nav-invert.nav-holder.nav-moved-background .nav-primary > li.menu-item-has-children > a:after {background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.664' height='4.332' viewBox='0 0 7.664 4.332'%3E %3Cpath id='Path_466' data-name='Path 466' d='M1322.519%2C2012.541l3.125%2C3.125-3.125%2C3.125' transform='translate(2019.498 -1321.812) rotate(90)' fill='none' stroke='%23eb3f41' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E %3C/svg%3E ")}
.nav-invert.nav-holder.nav-moved-background .logo svg path {fill:$color-red}
.nav-invert.nav-holder.nav-moved-background {background-color: rgba(255,255,255,1)}

body.overflow-hidden .nav-holder {transform:translateY(-100%)!important}


@include media-breakpoint-up(lg) {
	.nav-moved.nav-holder{transform:translateY(-100%)}
	.nav-moved.nav-holder.nav-scroll-up{transform:translateY(0)}
	
	.nav-moved.nav-holder.nav-hide{transform:translateY(-100%)}


	/* Open overlay desktop */
	.menu-open .nav-overlay { visibility: visible; pointer-events: auto; opacity: 1; transition: opacity 0.3s 0.3s, visibility 0s 0.3s; }
	
	/* Spacer main*/

	.nav-spacer {height: 120px}

	/* Nav holder */
	


	/* Main nav */
	.nav-primary {display: flex;list-style: none;margin: 0;padding: 0;}
	.nav-primary > li {display: flex; align-items:center;padding: 0 35px}
	.nav-primary > li:last-child:after {display: none;}


	/* Main nav links */
	.nav-primary > li > a {color:$color-red; font-weight: 800; font-size: 16px; display: flex; align-items:center;padding:58px 0 38px 0; height: 100%; text-decoration: none; transition:all 0.4s ;white-space: nowrap; position: relative;}
	//.nav-moved.nav-holder .nav-primary > li > a {padding:40px 0 20px 0;}

	/* Nav moved */
	#logo-top, #logo-bottom  {transition:opacity 0.4s, transform 0.4s; transition-delay:0.2s}
	#logo-icon {transition:opacity 0.4s; transition-delay:0s}




	.nav-primary > li > a > em {overflow: hidden;}
	.nav-primary > li:hover > a {}

	.menu-hovered {z-index: 2;}


	/* Main nav children down arrow */
	.nav-primary > li.menu-item-has-children > a:after { content: ''; display: inline-block; width: 10px; height: 5px;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.664' height='4.332' viewBox='0 0 7.664 4.332'%3E %3Cpath id='Path_466' data-name='Path 466' d='M1322.519%2C2012.541l3.125%2C3.125-3.125%2C3.125' transform='translate(2019.498 -1321.812) rotate(90)' fill='none' stroke='%23eb3f41' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E %3C/svg%3E ");background-size: 10px 5px; background-repeat: no-repeat; margin-left: 6px; transition: all 0.3s; position: relative; top: 0px }
	.nav-primary > li.menu-item-has-children:hover a:after { transform: rotate(180deg) }

	/* Main + Seconndary hover link swipe effect */
	.nav-primary li a em {font-style: normal; overflow: hidden;}
	.nav-primary li a span { position: relative; display: inline-block; transition: transform 0.3s; }
	.nav-primary li a span::before { position: absolute; top: 100%; content: attr(data-hover); text-decoration: underline;text-underline-position: under; color: $color-red; font-weight: 800; -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }
	.nav-primary > li:hover > a > em > span, 
	.nav-primary > li:focus > a > em > span,
	.nav-primary > li.current-menu-item > a > em > span, 
	.nav-primary > li.current-menu-parent > a > em > span, 
	.nav-primary > li.menu-item-has-children > div > ul > li:hover > a > em > span, 
	.nav-primary > li.menu-item-has-children > div > ul > li:focus > a > em > span,
	.nav-primary > li.menu-item-has-children > div > ul > li.current-menu-item > a > em > span  { -webkit-transform: translateY(-100%); -moz-transform: translateY(-100%); transform: translateY(-100%); }

	/* Reveal secondary nav */
	.nav-primary > li.menu-item-has-children > div { display: flex; position: absolute; top:100%;margin:0; width: 100%; left:0; right: 0; border-top: 32px solid rgba(255,255,255,0);margin-top: -32px; transition: opacity 0.3s 0s, visibility 0s 0.3s; opacity: 0; visibility: hidden; }
	.nav-primary > li.menu-item-has-children:hover > div {visibility: visible;pointer-events: auto;opacity: 1;transition: opacity 0.3s 0.3s, visibility 0s 0.3s;}


	/* Reveal secondary nav purple line */
	//.nav-primary > li.menu-item-has-children > a:before {content: '';width: 100%;height: 1px;background-color: $color-red; position: absolute;left: 0;right: 0;bottom: 42px;;z-index: 99; transform:scaleX(0),; transition: transform 0.4s 0s;transform-origin: 0% 0%;}
	.nav-primary > li.menu-item-has-children:hover > a:before, 
	.nav-primary > li.menu-item-has-children.current-menu-item > a:before, .nav-primary > li.menu-item-has-children.current-menu-parent > a:before {transform:scaleX(1);transition: transform 0.4s 0.2s;}

	.nav-moved.nav-holder .nav-primary > li.menu-item-has-children > a:before {bottom: -4px;}

	/* Secondary nav ul */
	.nav-primary > li.menu-item-has-children > div > ul { display: flex; list-style: none;margin:0 auto;padding: 0;justify-content:flex-end;width: 100%;max-width: 2000px  }

	/* Secondary nav links */
	.nav-primary > li.menu-item-has-children > div > ul li {display: flex; flex:1; text-align: center; flex-direction:column; text-align: center;border-right: 1px solid #DCDDE2; border-top:1px solid #DCDDE2}
	.nav-primary > li.menu-item-has-children > div > ul li:last-child {border-right: none}
	.nav-primary > li.menu-item-has-children > div > ul li a { background-color: #fff; display: flex;flex-direction:column; text-decoration: none; color:#000; overflow: hidden; white-space: nowrap; height: 100%; align-items:center;justify-content: center;padding: 45px 30px; font-size: 19px; transition:background-color 0.3s}
	.nav-primary > li.menu-item-has-children > div > ul li a:hover { background-color: #F3F4F9}
	.nav-primary > li.menu-item-has-children > div > ul li.current-menu-item a {background-color: #F3F4F9!important;}

	.nav-primary > li.menu-item-has-children> div > ul li a span::before {text-decoration: none; color: #000; font-weight: 400 }

	.menu-opener { display: none }

	/* Invert nav */
	.nav-invert.nav-holder .nav-primary > li > a {color:#fff}
	.nav-invert.nav-holder .nav-primary > li.menu-item-has-children > a:after {background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.664' height='4.332' viewBox='0 0 7.664 4.332'%3E %3Cpath id='Path_466' data-name='Path 466' d='M1322.519%2C2012.541l3.125%2C3.125-3.125%2C3.125' transform='translate(2019.498 -1321.812) rotate(90)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E %3C/svg%3E ")}

		/* Hover */
		.nav-invert-hover .nav-invert.nav-holder {background-color: rgba(255,255,255,1);}
		.nav-invert-hover .nav-invert.nav-holder .nav-primary > li > a {color:$color-red}
		.nav-invert-hover .nav-invert.nav-holder .nav-primary > li.menu-item-has-children > a:after {background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.664' height='4.332' viewBox='0 0 7.664 4.332'%3E %3Cpath id='Path_466' data-name='Path 466' d='M1322.519%2C2012.541l3.125%2C3.125-3.125%2C3.125' transform='translate(2019.498 -1321.812) rotate(90)' fill='none' stroke='%23eb3f41' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E %3C/svg%3E ")}
		.nav-invert-hover .nav-invert.nav-holder .logo svg path {fill:$color-red}


		/* .nav-invert.nav-holder {transition:transform 0.6s, background-color 0.3s;}
		.nav-invert.nav-holder .nav-primary > li.menu-item-has-children:hover > div, 
		.nav-invert.nav-holder .nav-primary > li.menu-item-has-children.current-menu-item > div, 
		.nav-invert.nav-holder .nav-primary > li.menu-item-has-children.current-menu-parent > div { transition: all 0.4s 0s;}

		.nav-invert.nav-holder .nav-primary > li.menu-item-has-children > div {transition: opacity 0.2s 0s, visibility 0s 0.2s;}
		.nav-invert.nav-holder .nav-primary > li.menu-item-has-children:hover > div {transition: opacity 0.4s 0s} */

		//.nav-invert.nav-holder .nav-primary li a span::before {color: #fff; }



/************ LANGUAGE MENU ************/
/* Language */
	.lang-holder-nav {}
	.lang-holder, .lang-holder ul { list-style: none; margin: 0; padding: 0; }
	.lang-holder li.nav-lang { position: relative; }
	.lang-holder li.nav-lang > a { color: inherit; font-size: 16px; display: flex; align-items: center; padding: 58px 20px 38px 20px; height: 100%; text-decoration: none; transition: all 0.4s; white-space: nowrap; position: relative; width: 100%; }
	.lang-holder li.nav-lang > a span { position: relative; }
	.lang-holder li.nav-lang > a span:before { content: ''; display: block; position: absolute; top: 11px; right: -15px; width: 10px;height: 5px;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.664' height='4.332' viewBox='0 0 7.664 4.332'%3E %3Cpath id='Path_466' data-name='Path 466' d='M1322.519%2C2012.541l3.125%2C3.125-3.125%2C3.125' transform='translate(2019.498 -1321.812) rotate(90)' fill='none' stroke='%23eb3f41' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E %3C/svg%3E ");	transition: all 0.5s;background-size: 10px 5px;background-repeat: no-repeat;}
    .lang-holder li.nav-lang.login-hovered > a {}
    .lang-holder li.nav-lang.login-hovered > a span:before { transform: rotate(180deg); }


 /* Language opener container */ 
    .lang-holder li.nav-lang > ul { right: 0; position: absolute; top: 100%; width: 210px; z-index: 999; visibility: hidden; opacity: 0; z-index: 0; transform: translateY(0px); transition: opacity 0.3s, visibility 0s 0.3s, transform 0.3s; pointer-events: none; }

/* Language opener container open */
    .lang-holder li.nav-lang.login-hovered > ul { pointer-events: auto; visibility: visible; opacity: 1; z-index: 1; transform: translateY(0); transition: opacity 0.3s, transform 0.3s; }


/* Menu open li's */
    .lang-holder li.nav-lang ul li { position: relative; }
    .lang-holder li.nav-lang ul li a { display: block;font-size: 17px;position: relative; background-color: #f4f4f9; padding: 12px 20px; text-decoration: none; display: flex; align-items:center; transition: background-color 0.4s}
    .lang-holder li.nav-lang ul li a:after { content: ''; height: 1px; background-color: #ccc; display: block; position: absolute; bottom: 0; left: 0; right: 0;}
    .lang-holder li.nav-lang ul li a:hover {}
    .lang-holder li.nav-lang ul li a span {display: block;margin-left: 10px;transition:color 0.4s}
    .lang-holder li.nav-lang ul li a:hover span { color: $color-red;}
    .lang-holder li.nav-lang ul li a:hover p {color: #192846;}
    .lang-holder li.nav-lang ul li:last-child a:after {display: none;}

    .lang-holder li.nav-lang .active {display: none;}


    .nav-invert.nav-holder .lang-holder li.nav-lang > a span:before {background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.664' height='4.332' viewBox='0 0 7.664 4.332'%3E %3Cpath id='Path_466' data-name='Path 466' d='M1322.519%2C2012.541l3.125%2C3.125-3.125%2C3.125' transform='translate(2019.498 -1321.812) rotate(90)' fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E %3C/svg%3E ");	transition: all 0.5s;}
    .nav-invert.nav-holder.nav-moved-background .lang-holder li.nav-lang > a span:before {background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.664' height='4.332' viewBox='0 0 7.664 4.332'%3E %3Cpath id='Path_466' data-name='Path 466' d='M1322.519%2C2012.541l3.125%2C3.125-3.125%2C3.125' transform='translate(2019.498 -1321.812) rotate(90)' fill='none' stroke='%23EB3F41' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E %3C/svg%3E ");}
    
    .nav-invert.nav-holder .lang-holder li.nav-lang > a {color: rgba(255,255,255,0.5);}
    .nav-invert.nav-holder.nav-moved-background .lang-holder li.nav-lang > a {color: inherit;}
}



@include media-breakpoint-down(xl) {
	.nav-primary > li > a {padding:46px 0 26px 0;}
	.nav-primary > li.menu-item-has-children > div > ul li a {padding: 35px 30px; font-size: 17px;}
	.nav-spacer {height: 96px}
}


@include media-breakpoint-down(lg) {
	/* Open overlay mmobile */
	.body-menu-opened .nav-overlay { visibility: visible; pointer-events: auto; opacity: 1; transition: opacity 0.4s; }


	/* Spacer main*/
	.nav-spacer {height: 90px}
	
	/* Spacer*/
	.nav-spacer-secondary {display: none;}

	.nav-holder {height: 90px;}
	.nav-holder .container {display: flex; align-items:center;height: 100%;}


	.body-menu-opened .logo{opacity: 0; transform: translateX(-50%);}
	.body-menu-opened .nav-holder {background-color:rgba(255,255,255,0)!important; }


	.body-menu-opened { overflow: hidden!important }

	.menu-opener { display: block; width: 54px; margin-left: 0; height: 44px; top: 24px; right: calc(0.7rem + 2.1vw); position: fixed; cursor: pointer; overflow: hidden }
	.menu-opener span { position: absolute; left: 10px; display: block; width: 32px; height: 2px; background-color:$color-red; transition: top .3s 0.3s, transform .3s; transform-origin: center center }
	.body-menu-opened .menu-opener span { transition: top .3s, transform .3s 0.3s; transform-origin: center center }
	.menu-opener span:first-child { top: 13px; transform: rotate(0deg) }
	.menu-opener span:nth-child(2) { top: 21px; transition: width 0.3s, opacity 0.4s;display: none; }
	.menu-opener span:last-child { top: 29px }

	.body-menu-opened .menu-opener span:first-child { top: 21px; transform: rotate(45deg) }
	.body-menu-opened .menu-opener span:nth-child(2) { opacity: 0; width: 0; }
	.body-menu-opened .menu-opener span:last-child { top: 21px; transform: rotate(-45deg) }


	.nav-primary { visibility: hidden; position: fixed; top: 0; bottom: 0; width: 50%; right: 0;  margin-right: 0 !important; transition: transform 0.4s ease, visibility 0.4s 0s !important; background-color: #ffffff; padding: 90px 0 30px 0;margin: 0; overflow-y: auto; display: block; background-position: right -45px bottom 60px; background-repeat: no-repeat; background-size: contain; transform: translateX(100%);}
	.body-menu-opened .nav-primary { visibility: visible; transition: transform 0.4s ease, visibility 0s 0s!important;transform: translateX(0); }
	.nav-primary > li { height: auto; display: block; position: relative; }



	.nav-primary > li a:hover, .nav-primary > li.current-menu-item > a, .nav-primary > li.current-menu-parent > a { }
	.nav-primary > li:last-child > a {  }
	.nav-primary > li > a { padding: 18px 40px 10px 70px; font-size: 24px; background-color: #fff;text-decoration: none;display: flex;align-items: center;font-weight: 700; color:$color-red; position: relative; }

	.nav-primary li a em {font-style: normal;}

	.nav-primary > li.menu-item-has-children > a:before { content: ''; display: inline-block; position: absolute;left: 40px; width: 12px; height: 6px;background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.664' height='4.332' viewBox='0 0 7.664 4.332'%3E %3Cpath id='Path_466' data-name='Path 466' d='M1322.519%2C2012.541l3.125%2C3.125-3.125%2C3.125' transform='translate(2019.498 -1321.812) rotate(90)' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E %3C/svg%3E ");background-size: 12px 6px; background-repeat: no-repeat; margin: 0 10px 0 0; transition: all 0.3s }
	.nav-primary > li.menu-item-has-children > a.open:before { transform: rotate(180deg);background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7.664' height='4.332' viewBox='0 0 7.664 4.332'%3E %3Cpath id='Path_466' data-name='Path 466' d='M1322.519%2C2012.541l3.125%2C3.125-3.125%2C3.125' transform='translate(2019.498 -1321.812) rotate(90)' fill='none' stroke='%23000000' stroke-linecap='round' stroke-linejoin='round' stroke-width='1'/%3E %3C/svg%3E "); }
	.nav-primary > li.menu-item-has-children > a.open {}
	.nav-primary > li.menu-item-has-children ul li.current-menu-item > a, .nav-primary > li.menu-item-has-children ul li.current-menu-parent > a {font-weight: 700 }


	.nav-primary > li.menu-item-has-children > div {height: auto;}
	.nav-primary > li.menu-item-has-children > div > ul { max-height: 0; overflow: hidden; transition: all 0.4s; padding: 0 0; margin: 0; list-style: none; top: 84px; width: 100%; left: -20px }
	.nav-primary > li.menu-item-has-children.open > div > ul { max-height: 600px; padding: 0 0; overflow: hidden }
	.nav-primary > li.menu-item-has-children > div > ul li {margin-left: 0;}
	.nav-primary > li.menu-item-has-children > div > ul li a { padding: 10px 40px 10px 70px; font-size: 20px; text-decoration: none;color: inherit; display: block; }

	.nav-primary > li.menu-item-has-children > div > ul li:last-child a { padding-bottom: 40px }



} 

@include media-breakpoint-down(md) {
	.nav-primary {width: 70%; }

}

@include media-breakpoint-down(sm) {
	.nav-primary {width: 90%; }
	.nav-primary > li.menu-item-has-children > div > ul li a {font-size: 19px;}
	.nav-holder .logo {width: 107px;}
}


